<template>
  <div class="card card-custom">
    <div class="card-header">
    <div class="card-title d-flex align-items-center">
      <span class="card-icon">
        <i class="flaticon2-chat-1 text-primary"></i>
      </span>
      <h3 class="card-label ml-2 ">All Categories</h3>
    </div>
    <v-row class="align-items-center justify-content-end" no-gutters>
      <v-col cols="12" md="2" v-if="isAnyFilterApplied" class="d-flex justify-end px-2 clear-filters">
        <span class="cursor-pointer text-primary" @click="clearFilters">Clear Filters</span>
      </v-col>
      <v-col cols="12" md="3" class="px-2 mb-2">
        <v-text-field
          v-model="businessName"
          outlined
          dense
          hide-details
          placeholder="Business Name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="1" class="d-flex justify-end px-2 mb-2">
        <v-btn elevation="2" class="bg-primary text-light font-poppins"  @click="loadCategories">Search</v-btn>
      </v-col>
    </v-row>
  </div>
    <div class="card-body p-0">
      <div class="row my-5 px-2">
        <div class="col-sm-12">
          <v-data-table
            :headers="headers"
            :items="categories"
            :options.sync="options"
            :server-items-length="totalCategories"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="edit(item.id)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:footer.page-text>
              {{ `Displaying ${from} - ${to} of ${totalCategories} items` }}
            </template>
          </v-data-table>
          <v-pagination
            v-model="options.page"
            :length="pages"
            :total-visible="7"
            @input="loadCategories"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "businesses",
  data() {
    return {
      dialog: false,
      singleSelect: false,
      selected: [],
      headers: [
        { text: "Store Name", align: "left", sortable: false, value: "business.store_name" },
        { text: "Business Name", align: "left", sortable: false, value: "business.name" },
        { text: "Category Name", align: "left", sortable: false, value: "name" },
        { text: "Description", align: "left", sortable: false, value: "description" },
        { text: "Products", align: "left", sortable: false, value: "products_count" },
        // { text: "Actions", align: "center", sortable: false, value: "actions" }
      ],
      categories: [],
      businessName: "",
      options: {
        page: 1,
        itemsPerPage: 5,
      },
      totalCategories: 0,
      loading: false
    };
  },
  computed: {
    isAnyFilterApplied() {
      return this.businessName !== "";
    },
    from() {
      return (this.options.page - 1) * this.options.itemsPerPage + 1;
    },
    to() {
      return Math.min(this.options.page * this.options.itemsPerPage, this.totalCategories);
    },
    pages() {
      return Math.ceil(this.totalCategories / this.options.itemsPerPage);
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Business Categories" }]);
    this.loadCategories();
  },
  methods: {
    loadCategories() {
      this.loading = true;
      ApiService.post("admin/categories", {
        business_name: this.businessName,
        page: this.options.page,
        per_page: this.options.itemsPerPage
      }).then(({ data }) => {
        this.categories = data.data;
        this.totalCategories = data.total;
        this.loading = false;
      });
    },
    clearFilters() {
      this.businessName = "";
      this.options.page = 1;
      this.loadCategories();
    },
    edit(id) {
      // handle edit action
    }
  }
};
</script>
